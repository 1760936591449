import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { CancellationAndRefund, Home, NotFound, PrivacyPolicy, ShippingAndDelivery, TermsAndConditions } from './pages';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/terms-and-conditions">
          <TermsAndConditions />
        </Route>
        <Route path="/cancellation-and-refund">
          <CancellationAndRefund />
        </Route>
        <Route path="/shipping-and-delivery">
          <ShippingAndDelivery />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
